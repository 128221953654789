import { render, staticRenderFns } from "./StoreOwners.vue?vue&type=template&id=077ca47c&scoped=true&"
import script from "./StoreOwners.vue?vue&type=script&lang=ts&"
export * from "./StoreOwners.vue?vue&type=script&lang=ts&"
import style0 from "./StoreOwners.vue?vue&type=style&index=0&id=077ca47c&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077ca47c",
  null
  
)

export default component.exports