



























































































import { Component, Vue } from 'vue-property-decorator';
import { AuthorizedUser, StoreAccount } from '@/app/shared/utilities/static-types';
import { getUserById } from '@adminRoles/shared/actions';
import { getCurrentUser } from '@/app/shared/firebase/firebase-user';
import { loadStoreOwners } from '../shared/actions';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import { signInToPlatformWithUserAsAdmin } from '@/app/shared/utilities/helper-functions';

@Component({
  components: {},
  data() {
    return {
      headers: [
        {
          text: this.$t('store_name').toString(),
          value: 'STORE_NAME',
          sortable: true,
        },
        {
          text: this.$t('email').toString(),
          value: 'EMAIL',
          sortable: true,
        },
        {
          text: this.$t('contract_type').toString(),
          value: 'CONTRACT_TYPE',
          sortable: true,
        },
        {
          text: this.$t('store_type').toString(),
          value: 'STORE_TYPE',
          sortable: true,
        },
        {
          text: this.$t('actions').toString(),
          value: 'ACTIONS',
          sortable: false,
        },
      ],
    };
  },
})
export default class StoreOwners extends Vue {
  public currentUser: AuthorizedUser | null = null;
  public storeOwners: StoreAccount[] = [];
  public loading = false;

  public newStoreOwnerRoute = AdminRoutes.CREATE_STORE_OWNER;

  public pagination = {
    sortBy: 'NAME',
    descending: false,
  };

  public changeSort(column: string): void {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }

  public async created() {
    this.currentUser = await this.loadCurrentUser() || null;
    this.storeOwners = await loadStoreOwners();
  }

  public async editStoreOwner(id: string) {
    this.$router.push({
      ...AdminRoutes.EDIT_STORE_OWNER,
      params: {
        id,
      },
    });
  }

  public async signInWithStoreOwnerAccount(user: AuthorizedUser) {
    this.loading = true;
    try {
      await signInToPlatformWithUserAsAdmin(user);
    } finally {
      this.loading = false;
    }
  }
  public async loadCurrentUser() {
    const { uid } = (await getCurrentUser())!;
    return getUserById(uid);
  }
}
